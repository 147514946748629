import { Editor } from '@tinymce/tinymce-react';
import React, { Component } from 'react';
import Footer from '../components/Footer';
import LoadingSpinner from '../components/LoadingSpinner';
import { deleteNews, getCurrentUser, getNews, getVolunteers, postNews, postNewsFile } from '../services/http/authService';

export default class Home extends Component {
  racesRef = React.createRef();

  adding = "Dodavanje vijesti u toku. Molimo nemojte zatvarati ovaj prozor...";
  deleting = "Brisanje vijesti u toku. Molimo nemojte zatvarati ovaj prozor..."

  state = {
    englishBody: '',
    bosnianBody: '',
    selectedBosnianFile: 't',
    selectedEnglishFile: 't',
    englishTitle: '',
    bosnianTitle: '',
    user: '',
    isLoading: false,
    error: '',
    volunteers: [],
    sort_asc: true,
    last_sort: '',
    loading_spinner_text: '',
    news: [],
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    // this.getAllVolunteersHTTP();
    this.getAllNews();
  }

  getAllVolunteersHTTP = () => {
    getVolunteers().then(this.onGetVolunteersSuccess).catch(this.onGetVolunteersError);
  };

  getAllNews = () => {
    getNews().then(this.onGetNewsSuccess).catch(this.onGetNewsError);
  };

  onGetNewsError = (err) => {
    if (err && err.message) {
      if (err.message.includes('No news') || err.message.includes('Failed to fetch')) {
        return;
      }
    }
    alert(err.message);
  };

  onGetNewsSuccess = (res) => {
    const user = getCurrentUser();
    this.setState({ news: res.result, user });
  };

  onGetVolunteersError = (err) => {
    alert(err.message);
  };

  onGetVolunteersSuccess = (res) => {
    const user = getCurrentUser();
    this.setState({ volunteers: res.result, user });
  };

  mapVolunteers = (volunteers) => {
    return volunteers.map((el, i) => {
      return (
        <tr>
          <td>{el.full_name}</td>
          <td>{el.date_of_birth}</td>
          <td>{el.gender}</td>
          <td>{el.email}</td>
          <td>{el.country}</td>
          <td>{el.city}</td>
          <td>{el.passportID}</td>
          <td>{el.phone}</td>
        </tr>
      );
    });
  };

  onDeleteNews = (newsId) => {
    if (window.confirm('Jeste li sigurni da zelite obrisati post sa ID: ' + newsId)) {
      this.setState({ isLoading: true, loading_spinner_text: this.deleting });
      deleteNews(newsId).then(this.onDeleteNewsSuccess).catch(this.onDeleteNewsError);
    }
  };

  onDeleteNewsError = (err) => {
    this.setState({ isLoading: false, error: err.message + ' - molimo kontaktirajte administratora' });
  };

  onDeleteNewsSuccess = (res) => {
    alert('Post uspjesno obrisan');
    this.setState({ isLoading: false, error: '' });
  };

  formatLinks = (links) => {
    return links.map((el) => {
      return (
        <td>
          <a href={el}>Link</a>
        </td>
      );
    });
  };

  formatText = (text) => {
    const MAX_CHARS_TABLE = 300;
    if (text.length >= MAX_CHARS_TABLE) {
      return text.substring(0, MAX_CHARS_TABLE) + '...';
    }
    return text;
  };

  mapNews = (news) => {
    return news.map((el, i) => {
      const txt = this.formatText(el.content);
      return (
        <tr>
          <td>{el.id}</td>
          <td>{this.formatText(el.title)}</td>
          <td>
            <div dangerouslySetInnerHTML={{ __html: txt }}></div>
          </td>
          {this.formatLinks(el.links)}
          <th style={{ color: 'red' }} onClick={(e) => this.onDeleteNews(el.id)}>
            Obrisati
          </th>
        </tr>
      );
    });
  };

  sortVolunteers = (e, by) => {
    e.preventDefault();
    const { volunteers, last_sort, sort_asc } = this.state;
    let sorted;
    let sort_asc_state = sort_asc;
    if (by == last_sort) {
      sort_asc_state = !sort_asc_state;
    }
    sorted = volunteers.sort((a, b) => {
      if (a[by] < b[by]) {
        return sort_asc_state ? -1 : 1;
      }
      if (a[by] > b[by]) {
        return sort_asc_state ? 1 : -1;
      }
      return 0;
    });
    this.setState({ volunteers: sorted, last_sort: by, sort_asc: sort_asc_state });
  };

  sortNews = (e, by) => {
    e.preventDefault();
    const { news, last_sort, sort_asc } = this.state;
    let sorted;
    let sort_asc_state = sort_asc;
    if (by == last_sort) {
      sort_asc_state = !sort_asc_state;
    }
    sorted = news.sort((a, b) => {
      if (a[by] < b[by]) {
        return sort_asc_state ? -1 : 1;
      }
      if (a[by] > b[by]) {
        return sort_asc_state ? 1 : -1;
      }
      return 0;
    });
    this.setState({ news: sorted, last_sort: by, sort_asc: sort_asc_state });
  };

  handleTitleBosnianChange = (e) => {
    this.setState({ bosnianTitle: e.target.value });
  };

  // handleTitleEnglishChange = e => {
  //   this.setState({ englishTitle: e.target.value });
  // }

  // handleEnglishChange = (e) => {
  //   this.setState({ englishBody: e });
  // }

  handleBosnianChange = (e) => {
    this.setState({ bosnianBody: e });
  };

  // onEnglishFileChange = event => {
  //   this.setState({ selectedEnglishFile: event.target.files[0] });
  // };

  onBosnianFileChange = (event) => {
    this.setState({ selectedBosnianFile: event.target.files[0] });
  };

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  }

  // onSendClickBosnian = async (e) => {
  //   const { bosnianBody, bosnianTitle, selectedBosnianFile } = this.state;
  //   for (const [key, value] of Object.entries({ bosnianBody, bosnianTitle, selectedBosnianFile })) {
  //     if (value == "") {
  //       let txt = key;
  //       const meta = {
  //         "bosnianBody": "Tekst na bosanskom",
  //         "bosnianTitle": "Naslov na bosanskom",
  //       }
  //       txt = meta[key];
  //       alert(txt + " mora biti naveden");
  //       return;
  //     }
  //   }
  //   const baFile = selectedBosnianFile.name;
  //   if (!baFile) { alert("PNG file mora biti naveden"); return; }
  //   const baExtension = baFile.substr(baFile.lastIndexOf('.') + 1);
  //   if (baExtension != "png") {
  //     alert("Samo .png fajlovi su podrzani");
  //     return;
  //   }
  //   try {
  //     const bosnianBase64 = await this.readFileAsync(selectedBosnianFile);
  //     const reqBody = {
  //       bosnian: {
  //         title: bosnianTitle,
  //         content: bosnianBody,
  //         image_title: baFile,
  //         image: bosnianBase64
  //       }
  //     }
  //     this.startPostNews(reqBody);
  //   }
  //   catch (err) {
  //     console.log(err);
  //   }
  // }

  // onSendClickEnglish = async (e) => {
  //   const { englishBody, englishTitle, selectedEnglishFile } = this.state;
  //   for (const [key, value] of Object.entries({ englishBody, englishTitle, selectedEnglishFile })) {
  //     if (value == "") {
  //       let txt = key;
  //       const meta = {
  //         "englishBody": "Tekst na engleskom",
  //         "englishTitle": "Naslov na engleskom"
  //       }
  //       txt = meta[key];
  //       alert(txt + " mora biti naveden");
  //       return;
  //     }
  //   }
  //   const enFile = selectedEnglishFile.name;
  //   if (!enFile) { alert("English file must be provided"); return; }
  //   const enExtension = enFile.substr(enFile.lastIndexOf('.') + 1);
  //   if (enExtension != "png") {
  //     alert("Samo .png fajlovi su podrzani");
  //     return;
  //   }
  //   try {
  //     const englishBase64 = await this.readFileAsync(selectedEnglishFile);
  //     const reqBody = {
  //       english: {
  //         title: englishTitle,
  //         content: englishBody,
  //         image_title: enFile,
  //         image: englishBase64
  //       }
  //     }
  //     this.startPostNews(reqBody);
  //   }
  //   catch (err) {
  //     console.log(err);
  //   }
  // }

  onSendClick = async (e) => {
    let { bosnianBody, bosnianTitle, selectedBosnianFile } = this.state;
    for (const [key, value] of Object.entries({ bosnianBody, bosnianTitle, selectedBosnianFile })) {
      if (value == '') {
        let txt = key;
        const meta = {
          bosnianBody: 'Tekst',
          bosnianTitle: 'Naslov',
        };
        txt = meta[key];
        alert(txt + ' mora biti naveden');
        return;
      }
    }
    // const baFile = selectedBosnianFile.name;
    // if (!baFile) {
    //   alert('PNG file mora biti naveden');
    //   return;
    // }
    // const baExtension = baFile.substr(baFile.lastIndexOf('.') + 1);
    // if (baExtension != 'png') {
    //   alert('Samo .png fajlovi su podržani');
    //   return;
    // }
    try {
      // const bosnianBase64 = await this.readFileAsync(selectedBosnianFile);
      const reqBody = {
        bosnian: {
          title: bosnianTitle,
          content: bosnianBody,
          image_title: "",
          // image: bosnianBase64,
          image: null
        },
      };
      // this.startPostNews(reqBody);


      const formData = new FormData();
      formData.append('title', bosnianTitle);
      formData.append('content', bosnianBody);
      formData.append('image_title', "baFile");
      formData.append('selectedBosnianFile', selectedBosnianFile);
      this.startPostNews(formData);

      // const fileReader = new FileReader();
      // fileReader.readAsArrayBuffer(selectedBosnianFile);
      // let chunkNumber = 0;
      // fileReader.onload = (event) => {
      //   const chunk = event.target.result;
      //   // send the chunk to the server here
      //   formData.append(`chunk-${chunkNumber}`, chunk);
      //   chunkNumber += 1;
      // };
      // fileReader.onloadend = (event) => {
      //   this.startPostNews(formData);
      // }

    } catch (err) {
    }
  };

  startPostNews = (body) => {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true, loading_spinner_text: this.adding});
    postNewsFile(body)
      .then((res) => {
        this.onNewsSuccess(res.result);
      })
      .catch((err) => {
        this.onNewsFailed(err.message);
      });
  };

  onNewsSuccess = (res) => {
    alert('Vijest uspjesno dodana');
    this.setState({ isLoading: false, error: '', selectedBosnianFile: '', bosnianBody: '', bosnianTitle: '' });
    this.getAllNews();
  };

  onNewsFailed = (err) => {
    this.setState({ isLoading: false, error: err, selectedBosnianFile: '', bosnianBody: '', bosnianTitle: '' });
  };

  render() {
    const { volunteers, news } = this.state;
    return (
      <div className="main-cont">
        {this.state.isLoading == true ? (
          <div className="text-center" style={{ marginTop: '150px', marginBottom: '150px' }}>
            <LoadingSpinner height={100} width={100} text={this.state.loading_spinner_text} />
          </div>
        ) : (
          <main id="main" ref={this.racesRef}>
            <section id="featured" class="featured">
              <div class="container">
                <div class="section-title" data-aos="fade-up">
                  <h2>Kreiraj vijest</h2>
                </div>
                {this.state.error && <div className="error-text big">{'Greška: ' + this.state.error}</div>}
                <div class="row">
                  <div class="col-lg-3"> </div>
                  <div class="col-lg-6">
                    <div class="icon-box fivek">
                      <div class="text-block text-center">{/* <h2>Bosanski jezik</h2> */}</div>

                      <label for="fname">Naslov</label>
                      <br></br>
                      <input type="text" id="fname" name="fname" style={{ width: '100%' }} onChange={this.handleTitleBosnianChange} />
                      <br />
                      <br />

                      <label for="fname">Tekst</label>
                      <br></br>
                      <Editor
                        apiKey="8eho2hwx7d8zbgwsyk5ylmxpgzpcrtf9qr381b4a970373wi"
                        initialValue=""
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                          ],
                          toolbar:
                            'undo redo | link | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help',
                          entity_encoding: 'raw',
                          contextmenu: false,
                        }}
                        onEditorChange={this.handleBosnianChange}
                      />
                      <br></br>
                      <label for="fname">Slika</label>
                      <br></br>
                      <input type="file" id="fname" name="fname" style={{ width: '100%' }} onChange={this.onBosnianFileChange} />
                      <br />
                      <br />
                      <div class="text-center">
                        <button class="btn btn-sm box-btn" onClick={this.onSendClick}>
                          Pošalji
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3"> </div>
                  {/* <div class="col-lg-6">
                    <div class="icon-box fivek">

                      <div class="text-block text-center">
                        <h2>Engleski jezik</h2>
                      </div>

                      <label for="fname">Naslov</label><br></br>
                      <input type="text" id="fname" name="fname" style={{ width: "100%" }} onChange={this.handleTitleEnglishChange} /><br /><br />

                      <label for="fname">Tekst</label><br></br>
                      <Editor
                        apiKey="k173mvfsgoy11rn9tez1eqidthfh7ib0qnbwpn86ih8nx9en"
                        initialValue=""
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar:
                            'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                             bullist numlist outdent indent | removeformat | help',
                          entity_encoding: "raw"
                        }}
                        onEditorChange={this.handleEnglishChange}
                      />
                      <br></br>
                      <label for="fname">Slika</label><br></br>
                      <input type="file" id="fname" name="fname" style={{ width: "100%" }} onChange={this.onEnglishFileChange} /><br /><br />
                      <div class="text-center">

                        <button
                          class="btn btn-sm box-btn"
                          onClick={this.onSendClickEnglish}
                        >
                          Pošalji
						          </button>

                      </div>
                    </div>
                  </div> */}
                </div>

                {/* <div class="row" style={{ height: "60px" }}>
                  <div class="col-lg-4">

                  </div>
                  <div class="col-lg-4 text-center">

                    <button
                      class="btn btn-sm box-btn"
                      onClick={this.onSendClick}
                    >
                      Pošalji
						          </button>

                  </div>

                  <div class="col-lg-4">

                  </div>

                </div> */}
              </div>
            </section>

            <section id="featured" class="featured">
              <div class="container">
                <div class="section-title" data-aos="fade-up">
                  <h2>Sve vijesti</h2>
                </div>
                <table border="1">
                  <tr>
                    <th
                      onClick={(e) => {
                        this.sortNews(e, 'id');
                      }}
                    >
                      Id&#9662;
                    </th>
                    <th
                      onClick={(e) => {
                        this.sortNews(e, 'title');
                      }}
                    >
                      Naslov&#9662;
                    </th>
                    <th
                      onClick={(e) => {
                        this.sortNews(e, 'content');
                      }}
                    >
                      Tekst&#9662;
                    </th>
                    <th>Park Ravne 2</th>
                    <th>Visit</th>
                    <th>Brisanje</th>
                  </tr>
                  {this.mapNews(news)}
                </table>
              </div>
            </section>
          </main>
        )}

        {/* <section id="featured" class="featured">
          <div class="container">
            <div class="section-title" data-aos="fade-up">
              <h2>Volonteri</h2>
            </div>
            <table border="1">
              <tr>
                <th onClick={(e) => { this.sortVolunteers(e, "full_name") }}>Ime&#9662;</th>
                <th onClick={(e) => { this.sortVolunteers(e, "date_of_birth") }}>Datum rođenja&#9662;</th>
                <th onClick={(e) => { this.sortVolunteers(e, "gender") }}>Spol&#9662;</th>
                <th onClick={(e) => { this.sortVolunteers(e, "email") }}>Email&#9662;</th>
                <th onClick={(e) => { this.sortVolunteers(e, "country") }}>Država&#9662;</th>
                <th onClick={(e) => { this.sortVolunteers(e, "city") }}>Grad&#9662;</th>
                <th onClick={(e) => { this.sortVolunteers(e, "passportID") }}>ID&#9662;</th>
                <th onClick={(e) => { this.sortVolunteers(e, "phone") }}>Tel&#9662;</th>
              </tr>
              {this.mapVolunteers(volunteers)}
            </table>
          </div>
        </section> */}

        <Footer></Footer>
      </div>
    );
  }
}
